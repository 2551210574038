.RegisterHeader {
  position: relative;
  text-align: center;
  padding: 30px 20px 15px;
  overflow: hidden;
}

/* .RegisterHeader h3 {
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
} */

.RegisterHeader > p {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RegisterHeader__meta > span + span:before {
  content: "•";
}

.RegisterHeader .RegisterHeader__close {
  position: absolute;
  top: 10px;
  left: 10px;
}
